import _ from "lodash";
import moment from "moment";
import template from "./hero.component.html";
import openwareConfig from "../openware.config.js";
import markerIconRed from "/public/images/marker-icon-red.png";
import markerIconOrange from "/public/images/marker-icon-orange.png";
import markerShadow from "/public/images/marker-shadow.png";

// get geojson url via webpack
import GEO_JSON_URL from "./geo.geojson";

let $user;
let $router;
let $data;
let $adapter;
let $http;
let $q;
let $timeout;
let $interval;
let $env;
let $dashboard;
let $location;
let $notification;
let initialZoom = false;

let loadLocationListCache;

class controller {
  static get $inject() {
    return ["$injector"];
  }

  constructor($injector) {
    this.loading = true;

    this.chart = null;
    this.login = false;

    this.map = null;

    this.filter = "Deutschland";

    this.searchString = "";
    this.filterFunction = null;

    $q = $injector.get("$q");
    $http = $injector.get("$http");
    $timeout = $injector.get("$timeout");
    $interval = $injector.get("$interval");

    $router = $injector.get("opendash/services/router");
    $user = $injector.get("opendash/services/user");
    $data = $injector.get("opendash/services/data");
    $env = $injector.get("opendash/services/env");
    $dashboard = $injector.get("opendash/services/dashboard");
    $location = $injector.get("opendash/services/location");
    $notification = $injector.get("opendash/services/notification");

    this.availableLocations = [];
    this.locations = [];

    $location.wait().then(() => {
      this.locSelectionOptions = {
        locationServiceMode: true,
        search: true,
        multi: false,
        maxHeight: "160px",
        filter: location => this.filterLocation(location)
      };
    });

    $router.onChange(state => {
      this.map = null;
      this.init();
    });

    this.init();
  }
  get isMobile() {
    // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return window.innerWidth < 1200;
  }

  get choice() {
    try {
      return $location.current[0].name;
    } catch (error) {
      return "-";
    }
  }

  showHero() {
    return $router &&
      $router.current &&
      $router.current.component === "od-dashboard"
      ? true
      : false;
  }

  async init() {
    // console.log("init");
    try {
      if (!this.showHero()) {
        return;
      }

      await $user.wait();
      await $data.wait();

      await $timeout(() => { }, 100);

      // render map only if not on mobile
      if (this.isMobile || this.map) return;

      // create leafmap
      this.mapInit();

      // set marker:
      //this.marker = new L.FeatureGroup();
      this.marker = [];
      this.placeMarker();
      setInterval(() => {
        this.placeMarker();
      }, 300000);

      //this.loading = false;
      // set control:
      //L.control.layers(L.tileLayer("")).addTo(this.map);
    } catch (error) {
      // console.warn(error);
    }

    await $q.resolve();
  }

  //orangeMarker
  getOrangeIndicator(dataItems) {
    var result = [true, false, ""];
    var orangeMarkerList = ["100%", "75%"];
    if (dataItems.length > 0) {
      dataItems.forEach(dataItemObject => {
        var dataItemDimensionIndex = 0;
        dataItemObject.valueTypes.forEach(dataItemObjectDimension => {
          for (var i = 0; i < orangeMarkerList.length; i++) {
            if (dataItemObjectDimension.name.toLowerCase().includes(orangeMarkerList[i].toLowerCase())) {
              try {
                if (dataItemObject.values[0].value[dataItemDimensionIndex] === true) {
                  result = [true, true, orangeMarkerList[i]];
                }
              } catch (error) {
                console.error(error);
              }
            }
          }
          dataItemDimensionIndex++;
        });
      });
      return result;
    } else {
      return [false, false, ""];
    }
  }

  getRedIndicator(dataItems) {
    var result = [true, false, ""];
    var redMarkerList = ["Störung", "Error", "Storing"];
    if (dataItems.length > 0) {
      dataItems.forEach(dataItemObject => {
        var dataItemDimensionIndex = 0;
        dataItemObject.valueTypes.forEach(dataItemObjectDimension => {
          for (var i = 0; i < redMarkerList.length; i++) {
            if (dataItemObjectDimension.name.toLowerCase().includes(redMarkerList[i].toLowerCase())) {
              try {
                if (dataItemObject.values[0].value[dataItemDimensionIndex] === true) {
                  result = [true, true, redMarkerList[i]];
                }
              } catch (error) {
                console.error(error);
              }
            }
          }
          dataItemDimensionIndex++;
        });
      });
      return result;
    } else {
      return [false, false, ""];
    }
  }

  placeMarker() {
    if (this.map) {
      try {
        //Remove Markers
        this.oms.clearMarkers();
        this.marker.forEach(item => {
          this.map.removeLayer(item);
        });

        //Get all Data:
        $http.get(openwareConfig.backend.protocol + openwareConfig.backend.base_url + "/api/data/items/" + $user.user.username, {
          headers: {
            "OD-SESSION": $user.user.session
          }
        }).then(dataItemList => {

          dataItemList = dataItemList.data;
          //Iterate through all Location
          var markerCounter = 0;
          $location.locations.forEach(location => {
            //Create an Array of all Data Items from this Location
            var locationDataItemArray = _.map(dataItemList, function (dataItem) {
              if (dataItem.user === location.spsid && location.spsid !== undefined) return dataItem
            });
            locationDataItemArray = _.without(locationDataItemArray, undefined)

            var orangeIndicator = this.getOrangeIndicator(locationDataItemArray);
            var redIndicator = this.getRedIndicator(locationDataItemArray);


            if (location && location.location) {
              if (location.location.latitude != 0 && location.location.longitude != 0) {


                var loc = new L.LatLng(location.location.latitude, location.location.longitude);
                var markers = new L.Marker(loc);

                var eventText = "OK";
                if (redIndicator[1]) {
                  eventText = redIndicator[2]
                  var redIconM = L.icon({
                    iconUrl: markerIconRed,
                    shadowUrl: markerShadow,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41]
                  });
                  markers = new L.Marker(loc, {
                    icon: redIconM
                  });
                } else if (orangeIndicator[1]) {
                  eventText = orangeIndicator[2]
                  var orangeIconM = L.icon({
                    iconUrl: markerIconOrange,
                    shadowUrl: markerShadow,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41]
                  });
                  markers = new L.Marker(loc, {
                    icon: orangeIconM
                  });
                }


                this.marker.push(markers);
                markers.index = markerCounter;
                markers.parseid = location.id;

                this.map.addLayer(markers);

                markers
                  .bindPopup("")
                  .on("mouseover", marker => {
                    markers.openPopup();
                    markers._popup.setContent(
                      "<div style='text-align:center'>" + location.name + "<br />" + "- " + eventText + " -</div>"
                    );
                  })
                  .on("mouseout", marker => {
                    markers.closePopup();
                  });

                this.oms.addMarker(markers);
                markerCounter = markerCounter + 1;


              }



            }

          })


          //Intial Zoom to Fit all Markers
          if (!initialZoom) {
            setTimeout(() => {
              //this.fitMapBounds();
              //initialZoom = true;
            }, 500);
          }
        }).catch(data => {
          /*
          * Data Handling in Case of Error (only show standard markers)
          */
          console.log("No Data available!");
          var markerCounter = 0;
          $location.locations.forEach(location => {
            if (location && location.location) {
              var loc = new L.LatLng(location.location.latitude, location.location.longitude);
              var markers = new L.Marker(loc);
              this.marker.push(markers);
              markers.index = markerCounter;
              markers.parseid = location.id;
              this.map.addLayer(markers);
              markers
                .bindPopup("")
                .on("mouseover", marker => {
                  markers.openPopup();
                  markers._popup.setContent(
                    "<div style='text-align:center'>" + location.name + "</div>"
                  );
                })
                .on("mouseout", marker => {
                  markers.closePopup();
                });

              this.oms.addMarker(markers);
              markerCounter = markerCounter + 1;
            }
          });
          //Intial Zoom to Fit all Markers
          if (!initialZoom) {
            setTimeout(() => {
              //this.fitMapBounds();
              //initialZoom = true;
            }, 500);
          }
        })


      } catch (error) {
        console.error(error);
      }
    }
  }



  fitMapBounds() {
    // Get all visible Markers
    const visibleMarkers = [];
    this.map.eachLayer(function (layer) {
      if (layer instanceof L.Marker) {
        visibleMarkers.push(layer);
      }
    });

    // Ensure there's at least one visible Marker
    if (visibleMarkers.length > 0) {

      // Create bounds from first Marker then extend it with the rest
      const markersBounds = L.latLngBounds([visibleMarkers[0].getLatLng()]);
      visibleMarkers.forEach((marker) => {
        markersBounds.extend(marker.getLatLng());
      });

      // Fit the map with the visible markers bounds
      this.map.flyToBounds(markersBounds, {
        padding: L.point(8, 50), animate: true,
      });
    }
  }





  setFilter(filter, filterFunction) {
    Object.keys(this.map._layers).forEach(key => {
      if (this.map._layers[key].setStyle) {
        this.map._layers[key].setStyle({
          color: "rgba(100,100,100,0.8)",
          weight: 0.7
        });
      }
    });

    $q.resolve().then(() => {
      if (filter) {
        this.filter = filter;
        this.filterFunction = filterFunction;
      } else {
        this.filter = "Deutschland";
        this.filterFunction = null;
      }
    });
  }

  filterLocation(location) {
    try {
      return this.filterFunction(location);
    } catch (error) {
      return true;
    }
  }

  async loadGeoJson() {
    let response = await fetch(GEO_JSON_URL);
    return await response.json();
  }

  async mapInit() {
    if (this.map) return;

    var tile_layer = L.tileLayer(
      "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png",
      {}
    );

    this.map = L.map("ow__hero__map", {
      dragging: false,
      touchZoom: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      tap: false,
      keyboard: false,
      zoomControl: false,
      attributionControl: false,
      prefix: false,
      zoomSnap: 0.1,
      minZoom: 2.9,
      maxZoom: 16,
      center: [51.3, 19.5],
      zoom: 5.3
    });

    tile_layer.addTo(this.map);
    tile_layer.on("load", e => {
      this.loading = false;
    });

    var lightIcon = L.Icon.Default;

    this.oms = new OverlappingMarkerSpiderfier(this.map, {
      keepSpiderfied: true
    });
    var popup = new L.Popup();
    this.oms.addListener("click", marker => {
      // console.log("Click on Marker OMS Listener");
      $location.setLocation([marker.parseid]);
    });
    this.oms.legColors.highlighted = "#439eca";

    this.map.on("click", e => {
      this.setFilter(null);
    });

    const geoJsonGermany = await this.loadGeoJson();

    //Deutschlandkarte
    
    const geo = L.geoJSON(geoJsonGermany, {
      clickable: false,
      style: {
        stroke: true,
        weight: 1.3,
        color: "rgba(100,100,100,0.9)",
        fill: true,
        //fillColor: "rgba(125, 141, 201, 0.3)", //purple
        fillColor: "rgba(41,116,179,0.2)", //blue
        //fillColor: "rgba(187,46,65,0.2)", //red
        fillOpacity: 1
      },
      onEachFeature: (feature, layer) => {
        layer.on({
          mouseout: function mouseout(e) {
            this.setStyle({
              //fillColor: "rgba(125, 141, 201, 0.3)" //purple
              fillColor: "rgba(41,116,179,0.2)" //blue
              //fillColor: "rgba(187,46,65,0.2)" //red
            });
          },
          mouseover: function mouseover(e) {
            this.setStyle({
              fillColor: "rgba(100,100,100,0.5)"
            });
          },
          click: e => {
            L.DomEvent.stopPropagation(e);

            const layer = e.target;
            const highlightColor = "black";
            const selected = e.target.options.color == highlightColor;

            if (selected) {
              this.setFilter(null);
              layer.setStyle({
                color: "rgba(100,100,100,0.8)",
                weight: 0.7
              });
            } else {
              let filter = e.target.feature.properties.GEN;
              this.setFilter(filter, location => location.province === filter);

              layer.bringToFront();
              layer.setStyle({
                color: highlightColor,
                weight: 2
              });
            }
          }
        });
      }
    });

    geo.addTo(this.map);
  }
}

let component = {
  template,
  controller
};

export default component;
